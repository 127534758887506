import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import useStyles from './styles';

export const ColumnHeader = ({ title, src }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.columnHeader} variant="body2">
      {src && <img className={classes.icon} src={src} alt="icon" />} {title}
    </Typography>
  );
};

ColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string
};
