import createReducer from '../../../utils/createReducer';
import * as constants from './elastic.constants';

const initialPagination = {
  dealsInfo: {
    page: 1,
    itemPerPage: 50,
    pageCounter: 0,
    itemCounter: 0
  },
  documentContent: {
    page: 1,
    itemPerPage: 50,
    pageCounter: 0,
    itemCounter: 0
  },
  topicsInfo: {
    page: 1,
    itemPerPage: 50,
    pageCounter: 0,
    itemCounter: 0
  },
  reportsInfo: {
    page: 1,
    itemPerPage: 50,
    pageCounter: 0,
    itemCounter: 0
  }
};

export const initialState = {
  searchResult: [],
  loading: false,
  availableFilters: {
    products: [],
    dealTypes: [],
    deals: [],
    currencies: [],
    reportTypes: [],
    customFieldMap: new Map()
  },
  filterValues: {
    products: [],
    dealTypes: [],
    deals: [],
    currencies: [],
    reportTypes: [],
    customFieldMap: new Map(),
    selectedDates: [null, null]
  },
  keyCustomFields: new Map(),
  pagination: initialPagination
};

export default createReducer(initialState, {
  [constants.GET_KEY_CUSTOM_FIELDS_SUCCESS](state, { payload }) {
    return {
      ...state,
      keyCustomFields: payload
    };
  },
  [constants.GET_SEARCH_RESULT](state) {
    return {
      ...state,
      loading: true
    };
  },
  [constants.GET_SEARCH_RESULT_SUCCESS](state, { payload }) {
    const {
      searchResult,
      filterUniqueValues,
      resetValues,
      paginationSettingsName,
      pageCounter,
      itemCounter,
      updateUniqueValues
    } = payload;
    const paginationUpdate = {
      ...state.pagination,
      [paginationSettingsName]: {
        ...state.pagination[paginationSettingsName],
        pageCounter,
        itemCounter
      }
    };
    return {
      ...state,
      searchResult: resetValues ? searchResult : [...state.searchResult, ...searchResult],
      availableFilters: updateUniqueValues ? filterUniqueValues : state.availableFilters,
      pagination: paginationUpdate,
      loading: false
    };
  },
  [constants.REMOVE_SEARCH_RESULT_BY_TYPE](state, { payload }) {
    return {
      ...state,
      searchResult: state.searchResult.filter((item) => item.cardType !== payload)
    };
  },
  [constants.UPDATE_FILTERS](state, { payload }) {
    return {
      ...state,
      filterValues: payload
    };
  },
  [constants.CLEAR_STATE](state) {
    return {
      ...state,
      searchResult: [],
      availableFilters: {
        products: [],
        dealTypes: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map()
      },
      filterValues: {
        products: [],
        dealTypes: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map(),
        dateFrom: '',
        dateTo: ''
      },
      pagination: initialPagination
    };
  },
  [constants.CLEAR_SEARCH_RESULT](state) {
    return {
      ...state,
      searchResult: []
    };
  },
  [constants.SET_LOADING](state, { payload }) {
    return {
      ...state,
      loading: payload
    };
  },
  [constants.UPDATE_PAGINATION_PAGE](state, { payload }) {
    const { cardType, newPage } = payload;
    const paginationUpdate = {
      ...state.pagination,
      [cardType]: {
        ...state.pagination[cardType],
        page: newPage
      }
    };
    return {
      ...state,
      pagination: paginationUpdate
    };
  }
});
