import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  columnHeader: {
    display: 'flex',
    gap: '4px',
    fontSize: '13px !important',
    fontWeight: 700,
    lineHeight: '17.73px',
    color: theme.palette.text.subtitle
  },
  icon: {
    width: '16px',
    height: '16px'
  }
}));

export default useStyles;
