import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ElasticSearchModal from './index';
import ElasticContext from './context/elastic.context';
import ElasticReducer, { initialState } from './context/elastic.reducer';
import { getProductAllowed } from '../../services/api/product';
import { showAlert } from '../../redux/actions';
import * as actionsCreator from './context/elastic.actions';

const ElasticModalPage = ({ open, onClose }) => {
  const [state, dispatch] = useReducer(ElasticReducer, initialState);
  const dispatchRedux = useDispatch();

  const getProducts = async () => {
    try {
      const allowedProducts = await getProductAllowed();
      const keyCustomFields = allowedProducts.reduce((map, product) => {
        product.customFields.forEach((field) => {
          if (field.isKeyField) {
            map.set(String(field.id), { name: field.name, type: field.type });
          }
        });
        return map;
      }, new Map());
      dispatch(actionsCreator.getKeyCustomFieldsSuccess(keyCustomFields));
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error.response?.data?.message })
      );
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ElasticContext.Provider value={{ state, dispatch }}>
      {open && <ElasticSearchModal open={open} onClose={onClose} />}
    </ElasticContext.Provider>
  );
};

ElasticModalPage.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ElasticModalPage.defaultProps = {
  open: false
};

export default ElasticModalPage;
