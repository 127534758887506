import * as constants from './elastic.constants';
import { REMOVE_SEARCH_RESULT_BY_TYPE, UPDATE_PAGINATION_PAGE } from './elastic.constants';

export const getKeyCustomFieldsSuccess = (keyCustomFields) => ({
  type: constants.GET_KEY_CUSTOM_FIELDS_SUCCESS,
  payload: keyCustomFields
});

export const getSearchResult = () => ({
  type: constants.GET_SEARCH_RESULT
});

export const getSearchResultSuccess = (
  searchResult,
  resetValues,
  filterUniqueValues,
  paginationSettingsName,
  pageCounter,
  itemCounter,
  updateUniqueValues
) => ({
  type: constants.GET_SEARCH_RESULT_SUCCESS,
  payload: {
    searchResult,
    resetValues,
    filterUniqueValues,
    paginationSettingsName,
    pageCounter,
    itemCounter,
    updateUniqueValues
  }
});

export const setLoading = (value) => ({
  type: constants.SET_LOADING,
  payload: value
});

export const updateFilters = (filters) => ({
  type: constants.UPDATE_FILTERS,
  payload: filters
});

export const clearState = () => ({
  type: constants.CLEAR_STATE
});

export const clearSearchResult = () => ({
  type: constants.CLEAR_SEARCH_RESULT
});

export const updatePaginationPage = (cardType, newPage) => ({
  type: UPDATE_PAGINATION_PAGE,
  payload: { cardType, newPage }
});

export const removeSearchResultByType = (cardType) => ({
  type: REMOVE_SEARCH_RESULT_BY_TYPE,
  payload: cardType
});
