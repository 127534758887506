import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { SourceRounded } from '@mui/icons-material';
import CustomTooltip from '../CustomTooltip';
import useStyles from './styles';
import { highlightText } from '../../utils/common';
import { dealFieldsTitleMap, excludedFieldsMap } from './elasticConstants';

const DealCard = ({ data, searchValue }) => {
  const classes = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      setIsEllipsisActive(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [data.reportName]);

  return (
    <div className={classes.card}>
      <div className={classes.cardTitleGroup}>
        <SourceRounded />
        <CustomTooltip title={isEllipsisActive ? data.dealName : ''} placement="bottom-start">
          <Link
            className={classes.cardTitleWithWrap}
            href={`/deal/${data.dealId}/document/${data.documentId}`}
            underline="none"
            target="_blank"
            ref={titleRef}
          >
            {data.dealName}
          </Link>
        </CustomTooltip>
      </div>
      <div className={classes.cardContent}>
        {data.values.map(
          (value) =>
            !excludedFieldsMap.has(value.field) && (
              <div className={classes.cardContentRow}>
                <div className={classes.fieldName}>
                  {dealFieldsTitleMap.get(value.field) || value.field}:
                </div>
                <div className={classes.fieldValue}>{highlightText(value.value, searchValue)}</div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

DealCard.propTypes = {
  data: PropTypes.object,
  searchValue: PropTypes.string
};

export default DealCard;
